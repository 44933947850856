import type { ApolloClient } from '@apollo/client';
import type { Client } from '@peloton/api';
import { useApq as shouldUseApq } from '@peloton/auth/config';
import { toLocaleFromTLD } from '@peloton/internationalize/models/locale';
import { addItemsToCart } from '@ecomm/cart/api/createCartItems';
import type { LocalResolvers, Types } from '@ecomm/graphql';
import { fromLocale, toCurrencyfromLocale } from '@ecomm/graphql-bridge';
import { RENTAL_SLUGS } from '@ecomm/rentals/models';
import { getCtSubscriptionProductVariantId } from '@ecomm/shop/commercetools/getCtSubscriptionProductVariantId';
import { SUBSCRIPTION_SLUG } from '@ecomm/shop/models/Product';
import type { ProductSelection } from '@ecomm/shop/models/Selections';
import type { BundleProductVariantsBySelectionsQuery } from './BundleProductVariantsBySelections.generated';
import { BundleProductVariantsBySelectionsDocument } from './BundleProductVariantsBySelections.generated';
import type { Variables as BundleVariables } from './useAddBundleToCartMutation';
import type { Variables as PackageVariables } from './useAddPackageToCartMutation';

export type Context = {
  apolloClient: ApolloClient<any>;
  restClient: Client;
  ctClient: ApolloClient<any>;
};

export const addBundleToCart: Types.Resolver<{}, {}, Context, BundleVariables> = async (
  _,
  { slug, locale, currency, bundleId, productSelections, bundleSlug },
  { apolloClient, restClient },
) => {
  const bundleProductVariantResponse = await apolloClient.query<BundleProductVariantsBySelectionsQuery>(
    {
      query: BundleProductVariantsBySelectionsDocument,
      context: { useApq: shouldUseApq() },
      variables: {
        slug,
        locale,
        currency,
        productSelections,
        bundleSlug,
      },
    },
  );

  const productOptionIds = bundleProductVariantResponse?.data.catalog.bundleProductVariantsBySelections?.map(
    (variant: Types.ProductVariant) => variant.id,
  );

  if (productOptionIds == null) return;

  const cart = await addItemsToCart(restClient, {
    bundles: [
      {
        bundleId,
        productOptions: productOptionIds,
      },
    ],
  });

  return cart;
};

export const getProductOptionIdsForKronosPackage = async (
  apolloClient: ApolloClient<any>,
  bundleSlug: string,
  slug: string,
  locale: string,
  currency: string,
  productSelections: ProductSelection[],
) => {
  const bundleProductVariantQuery = apolloClient.query<BundleProductVariantsBySelectionsQuery>(
    {
      query: BundleProductVariantsBySelectionsDocument,
      context: { useApq: shouldUseApq() },
      variables: {
        slug,
        locale,
        currency,
        productSelections,
        bundleSlug,
      },
    },
  );
  const response = await bundleProductVariantQuery;

  return response?.data.catalog.bundleProductVariantsBySelections?.map(
    (variant: Types.ProductVariant) => variant.id,
  );
};
export const getSubscriptionId = async (ctClient: ApolloClient<object>) => {
  const baseLocale = toLocaleFromTLD();
  const locale = fromLocale(baseLocale);
  const currency = toCurrencyfromLocale(baseLocale);
  return await getCtSubscriptionProductVariantId(
    ctClient,
    SUBSCRIPTION_SLUG,
    locale,
    currency,
  );
};

export const addPackageToCart: Types.Resolver<{}, {}, Context, PackageVariables> = async (
  _,
  {
    slug,
    locale,
    currency,
    packageId,
    hasTradeIn,
    productSelections,
    warrantySelection,
    bundleSlug,
    upsellIds,
    upsellBundles,
    productOptionIds,
    subscriptionId,
  },
  { apolloClient, restClient, ctClient },
) => {
  // do not auto-add subscriptions for these bundles
  const denylist = [
    'guide-strength-starter-us',
    'guide-strength-starter-md-22-us',
    'guide-strength-starter-md-22-ca',
    'guide-strength-starter-ca',
    'guide-strength-starter-uk',
    'guide-strength-starter-au',
    'guide-power-us',
    'guide-power-ca',
    'guide-md-22-us',
    'guide-md-22-ca',
    'guide-us',
    'guide-ca',
    'guide-uk',
    'guide-au',
    'guide-starter-package-us',
    'guide-starter-package-uk',
    'guide-starter-package-ca',
    'guide-starter-package-au',
    'guide-select-package-us',
    'guide-select-package-uk',
    'guide-select-package-ca',
    'guide-select-package-au',
    'guide-ultimate-package-us',
    'guide-ultimate-package-uk',
    'guide-ultimate-package-ca',
    'guide-ultimate-package-au',
    ...RENTAL_SLUGS,
  ];

  const autoAddSub = !denylist.includes(bundleSlug);
  let subscriptionProductId;

  if (autoAddSub && subscriptionId) {
    subscriptionProductId = subscriptionId;
  } else if (autoAddSub) {
    subscriptionProductId = await getCtSubscriptionProductVariantId(
      ctClient,
      SUBSCRIPTION_SLUG,
      locale,
      currency,
    );
  }

  const packageProductOptionIds =
    productOptionIds && productOptionIds.length > 0
      ? productOptionIds
      : await getProductOptionIdsForKronosPackage(
          apolloClient,
          bundleSlug,
          slug,
          locale,
          currency,
          [...productSelections].concat(warrantySelection ? [warrantySelection] : []),
        );

  if (packageProductOptionIds == null) return;
  const productsBody = upsellIds
    .map(productOptionId => ({ productOptionId, quantity: 1 }))
    .concat(
      autoAddSub ? [{ productOptionId: subscriptionProductId || '', quantity: 1 }] : [],
    );

  const cart = await addItemsToCart(restClient, {
    bundles: [
      {
        bundleId: packageId,
        productOptions: packageProductOptionIds,
        has_trade_in: hasTradeIn,
      },
    ].concat(upsellBundles.map(b => ({ ...b, has_trade_in: false }))),
    products: productsBody,
  });

  return cart;
};

export const addToCartResolver: LocalResolvers<any, any, any> = {
  resolvers: {
    Mutation: {
      addBundleToCart,
      addPackageToCart,
    } as any,
  },
};
